import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 30,
  },
  path: {
    fill: '#7df3e1',
  },
});
const LogoFull = () => {
  const classes = useStyles();

  return (
    <svg
    className={classes.svg}
    viewBox="0 0 580 95"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <image
      xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAkQAAABfCAYAAAAJbtgrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB dElEQVR42u3WQREAAAjDMMC/56GDI5HQVztJAQB8NhIAAIYIAMAQAQAYIgAAQwQAYIgAAAwRAIAh AgAwRAAAhggAwBABABgiAABDBABgiAAADBEAgCECADBEAACGCADAEAEAGCIAAEMEAGCIAAAMEQCA IQIAMEQAAIYIAMAQAQAYIgAAQwQAYIgAAAwRAIAhAgAwRAAAhggAwBABABgiAABDBABgiAAADBEA gCECADBEAACGCADAEAEAGCIAAEMEAGCIAAAMEQCAIQIAMEQAAIYIAMAQAQAYIgAAQwQAYIgAAAwR AIAhAgAwRAAAhggAwBABABgiAABDBABgiAAADBEAgCECADBEAACGCADAEAEAGCIAAEMEAGCIAAAM EQCAIQIAMEQAAIYIAMAQAQAYIgAAQwQAYIgAAAwRAIAhAgAwRAAAhggAwBABABgiAABDBABgiAAA DBEAgCECADBEAACGCADAEAEAGCIAAEMEAGCIAAAOWHZ4A7tpBhXIAAAAAElFTkSuQmCC"
      className={classes.svg}
    />
    <text
      transform="translate(1.294 6.89)"
      fontFamily="Earth Orbiter Bold"
      fontSize={95}
      fill="#fff"
      y={71.25}
    >
      {"rendezyou"}
    </text>
  </svg>
  );
};

export default LogoFull;
